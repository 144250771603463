/* eslint-disable */
let ticking = false;
const header = document.getElementsByClassName('header');
document.addEventListener('scroll', e => {
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(() => {
      lastKnownScrollPosition > 905 ? addShadow() : removeShadow();
      ticking = false;
    });
    ticking = true;
  }
});

function addShadow() {
  header[0].classList.add('header--shadow');
  $('.site-header__tabs').removeClass('is-visible');
}

function removeShadow() {
  header[0].classList.remove('header--shadow');
  $('.site-header__tabs').addClass('is-visible');
}