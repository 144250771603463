// eslint:disable
const button = document.getElementById("navBtn");
const menu = document.getElementById("mobileMenu");
let open = false;
button.addEventListener("click", toggleMenu);

function toggleMenu() {
  open = !open;

  if (open) {
    menu.classList.add("menu_active");
    button.classList.add("button_active");
  } else {
    menu.classList.remove("menu_active");
    button.classList.remove("button_active");
  }
}